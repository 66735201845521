import {thunk} from 'redux-thunk';
import userReducers from '../store/userStore';
import { configureStore } from '@reduxjs/toolkit';
import vehicleReducers from './vehicleStore';
import propertyReducers from './propertyStore';

const middleware = (getDefaultMiddleware) => {
  return getDefaultMiddleware().concat(thunk);
};

export const Store = configureStore({
    reducer: {
      userStore:userReducers,
      vehicleStore:vehicleReducers,
      propertyStore:propertyReducers
    },
    middleware
  })