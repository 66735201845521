import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { Store } from './store/rootStore';
import Spinner from './Components/customLazySpinner/CustomLazySpinner';
const LazyApp = lazy(() => import("./App"));


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<Spinner/>}>
    <Provider store={Store}>
    <BrowserRouter>
    <LazyApp  />
    </BrowserRouter>
    </Provider>

  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
