import { createSlice } from "@reduxjs/toolkit";

const vehicleSlice=createSlice({
    name:"Vehicle",
    initialState:{
        vehicleFilter:{
            location: "",
            category: "",
            minPrice: "",
            maxPrice: "",
            brand:"",
            bank_name_id: "",
            hashtag_id: "",
            price:"",
            latest:"",
            title:""
          },
          Refresh:false,
    },
    reducers:{
       setVehicleFilter:(state,actions)=>{
            let {key,value}=actions.payload
            if (key in state.vehicleFilter) {
                state.vehicleFilter[key] = value;
              } 
       },

       setVehiclePriceRange:(state,actions)=>{
        let {minPrice,maxPrice}=actions.payload
        state.vehicleFilter.minPrice = minPrice;
        state.vehicleFilter.maxPrice = maxPrice;
      },
      resetVehicleFilter :(state,actions)=>{
        state.vehicleFilter={
            location: "",
            category: "",
            minPrice: "",
            maxPrice: "",
            brand:"",
            bank_name_id: "",
            hashtag_id: "",
            price:"",
            latest:"",
            title:""
          }
      },
      setRefresh:(state)=>{
        state.Refresh=!state.Refresh
    }
    }
})

export const {setVehicleFilter,setVehiclePriceRange,resetVehicleFilter,setRefresh} = vehicleSlice.actions
export default vehicleSlice.reducer