import { createSlice } from "@reduxjs/toolkit";

const userSlice=createSlice({
    name:"User",
    initialState:{
        token:null,
        userDetails:null
    },
    reducers:{
        setToken:(state,actions)=>{
            state.token=actions.payload
        },
        setUserDetails:(state,actions)=>{
            state.userDetails=actions.payload
        }
       
    }
})

export const {setUserDetails,setToken} = userSlice.actions
export default userSlice.reducer